import React, { useState, useEffect } from 'react'
import AdminNav from '../../../Component/AdminNav/AdminNav';
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import { addCity, cityUpdate, getCity } from '../../../api/city';
import { addArea, areaUpdate, getAreaList } from '../../../api/area';
import { addCategory, categoryUpdate, getCategoryList } from '../../../api/category';
import { addSubCategory, getSubCategoryList, subCategoryUpdate } from '../../../api/sub-category';

const AddOption = () => {
  const [count, setCount] = useState(0);
  const [categoryId, setCategoryId] = useState("");
  const [cityId, setCityId] = useState("");

  const [category, setCategory] = useState("");
  const [categoryLists, setCategoryLists] = useState([]);
  const [editCategoryIndex, setEditCategoryIndex] = useState(null);
  const [editedCategory, setEditedCategory] = useState('');

  const [subCategory, setSubCategory] = useState("");
  const [subCategoryLists, setSubCategoryLists] = useState([]);
  const [editSubCategoryIndex, setEditSubCategoryIndex] = useState(null);
  const [editedSubCategory, setEditedSubCategory] = useState('');

  const [city, setCity] = useState("");
  const [cityLists, setCityLists] = useState([]);
  const [editCityIndex, setEditCityIndex] = useState(null);
  const [editedCity, setEditedCity] = useState('');

  const [area, setArea] = useState("");
  const [areaLists, setAreaLists] = useState([]);
  const [editAreaIndex, setEditAreaIndex] = useState(null);
  const [editedArea, setEditedArea] = useState('');

  const handleCategoryClick = (index, category) => {
    setEditCategoryIndex(index);
    setEditedCategory(category.category);
  };
  const handleCategoryChange = (e) => {
    setEditedCategory(e.target.value);
  };

  const handleSubCategoryClick = (index, subCategory) => {
    setEditSubCategoryIndex(index);
    setEditedSubCategory(subCategory.sub_category);
  };
  const handleSubCategoryChange = (e) => {
    setEditedSubCategory(e.target.value);
  };

  const handleCityClick = (index, city) => {
    setEditCityIndex(index);
    setEditedCity(city.city);
  };
  const handleCityChange = (e) => {
    setEditedCity(e.target.value);
  };

  const handleAreaClick = (index, area) => {
    setEditAreaIndex(index);
    setEditedArea(area.area);
  };
  const handleAreaChange = (e) => {
    setEditedArea(e.target.value);
  };


  useEffect(() => {
    getCategoryList().then((data) => {
      setCategoryLists(data);
    })
    getSubCategoryList().then((data) => {
      setSubCategoryLists(data);
    })
    getCity().then((data) => {
      setCityLists(data);
    })
    getAreaList().then((data) => {
      setAreaLists(data);
    })
  }, [count]);

  const categoryAdd = () => {
    let categoryInfo = {
      "id": uuidv4(),
      "category_name": category,
      "created_at": new Date().toISOString()
    }
    addCategory(categoryInfo).then((data) => {
      setCategory("")
      setCount(count + 1)
    })
  }

  const updateCategory = (index, id) => {
    let zoneInfo = {
      "category": editedCategory
    }
    categoryUpdate(id, zoneInfo).then((data) => {
      setEditCategoryIndex(null);
      setCount(count + 1)
    })
  };


  const subCategoryAdd = () => {
    let categoryInfo = {
      "id": uuidv4(),
      "category": categoryId,
      "sub_category": subCategory,
      "created_at": new Date().toISOString()
    }
    addSubCategory(categoryInfo).then((data) => {
      setSubCategory("")
      setCount(count + 1)
    })
  }


  const updateSubCategory = (index, id) => {
    let zoneInfo = {
      "sub_category": editedSubCategory
    }
    subCategoryUpdate(id, zoneInfo).then((data) => {
      setEditSubCategoryIndex(null);
      setCount(count + 1)
    })
  };



  const cityAdd = () => {
    let categoryInfo = {
      "id": uuidv4(),
      "city": city,
      "created_at": new Date().toISOString()
    }
    addCity(categoryInfo).then((data) => {
      setCity("")
      setCount(count + 1)
    })
  }

  const updateCity = (index, id) => {
    let zoneInfo = {
      "city": editedCity
    }
    cityUpdate(id, zoneInfo).then((data) => {
      setEditCityIndex(null);
      setCount(count + 1)
    })
  };


  const areaAdd = () => {
    let categoryInfo = {
      "id": uuidv4(),
      "city": cityId,
      "area": area,
      "created_at": new Date().toISOString()
    }
    addArea(categoryInfo).then((data) => {
      setArea("")
      setCount(count + 1)
    })
  }

  const updateArea = (index, id) => {
    let zoneInfo = {
      "area": editedArea
    }
    areaUpdate(id, zoneInfo).then((data) => {
      setEditAreaIndex(null);
      setCount(count + 1)
    })
  };


  return (
    <div>
      <AdminNav />
      <Container>
        <Row>
          <Col md={6} className='optionList'>
            <h1>Category list</h1>
            <div class="input-group mb-3">
              <input value={category} onChange={(e) => setCategory(e.target.value)} type="text" class="form-control" placeholder="Category" />
              <div class="input-group-append">
                <button class="btn btn-info" type="button" onClick={categoryAdd}>Add</button>
              </div>
            </div>
            <div className='categoriesList'>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Category Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {categoryLists.map((category, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        {editCategoryIndex === i ? (
                          <input type="text" value={editedCategory} onChange={handleCategoryChange} />
                        ) : (
                          category.category
                        )}
                      </td>
                      <td>
                        {editCategoryIndex === i ? (
                          <button className='acceptInfo' onClick={() => updateCategory(i, category.id)}>Accept</button>
                        ) : (
                          <button className='editInfo' onClick={() => handleCategoryClick(i, category)}>Edit</button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={6} className='optionList'>
            <h1>Sub-Category list</h1>
            <div className='inputField mt-3'>
              <label>Select Profile</label>
              <Form.Select aria-label="Default select example" onChange={(e) => setCategoryId(e.target.value)}>
                <option value="">Select Profile Option</option>
                {categoryLists.map((category) =>
                  <option value={`${category.id}`}>{category.category}</option>
                )}
              </Form.Select>
            </div>
            <div class="input-group mb-3">
              <input value={subCategory} onChange={(e) => setSubCategory(e.target.value)} type="text" class="form-control" placeholder="Category" />
              <div class="input-group-append">
                <button class="btn btn-info" type="button" onClick={subCategoryAdd}>Add</button>
              </div>
            </div>
            <div className='categoriesList'>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Category Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {subCategoryLists.map((subCategory, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{subCategory.category_name}</td>
                      <td>
                        {editSubCategoryIndex === i ? (
                          <input type="text" value={editedSubCategory} onChange={handleSubCategoryChange} />
                        ) : (
                          subCategory.sub_category
                        )}
                      </td>
                      <td>
                        {editSubCategoryIndex === i ? (
                          <button className='acceptInfo' onClick={() => updateSubCategory(i, subCategory.id)}>Accept</button>
                        ) : (
                          <button className='editInfo' onClick={() => handleSubCategoryClick(i, subCategory)}>Edit</button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={6} className='optionList'>
            <h1>City list</h1>
            <div class="input-group mb-3">
              <input value={city} onChange={(e) => setCity(e.target.value)} type="text" class="form-control" placeholder="Category" />
              <div class="input-group-append">
                <button class="btn btn-info" type="button" onClick={cityAdd}>Add</button>
              </div>
            </div>
            <div className='categoriesList'>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Category Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cityLists.map((city, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        {editCityIndex === i ? (
                          <input type="text" value={editedCity} onChange={handleCityChange} />
                        ) : (
                          city.city
                        )}
                      </td>
                      <td>
                        {editCityIndex === i ? (
                          <button className='acceptInfo' onClick={() => updateCity(i, city.id)}>Accept</button>
                        ) : (
                          <button className='editInfo' onClick={() => handleCityClick(i, city)}>Edit</button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={6} className='optionList'>
            <h1>Area list</h1>
            <div className='inputField mt-3'>
            <label>Select Profile</label>
            <Form.Select aria-label="Default select example" onChange={(e) => setCityId(e.target.value)}>
              <option value="">Select Profile Option</option>
              {cityLists.map((city) =>
                <option value={`${city.id}`}>{city.city}</option>
              )}
            </Form.Select>
          </div>
            <div class="input-group mb-3">
              <input value={area} onChange={(e) => setArea(e.target.value)} type="text" class="form-control" placeholder="Category" />
              <div class="input-group-append">
                <button class="btn btn-info" type="button" onClick={areaAdd}>Add</button>
              </div>
            </div>
            <div className='categoriesList'>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>City</th>
                    <th>Category Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {areaLists.map((area, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{area.city_name}</td>
                      <td>
                        {editAreaIndex === i ? (
                          <input type="text" value={editedArea} onChange={handleAreaChange} />
                        ) : (
                          area.area
                        )}
                      </td>
                      <td>
                        {editAreaIndex === i ? (
                          <button className='acceptInfo' onClick={() => updateArea(i, area.id)}>Accept</button>
                        ) : (
                          <button className='editInfo' onClick={() => handleAreaClick(i, area)}>Edit</button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AddOption
