import React, { useState } from 'react'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'
import { postComment } from '../../api/post';
import { v4 as uuidv4 } from 'uuid';

const CommentModal = ({ commentShow, postId, handleCommentClose }) => {
    const [comment, setComment] = useState('');
    const savedUserProfile = localStorage.getItem("niomit");
    const userProfile = JSON.parse(savedUserProfile);
    const commentPost = () => {
        let commentInfo = {
            "id": uuidv4(),
            "comment": comment,
            "post_id": postId,
            "created_by": userProfile?.mobile,
        }
        postComment(commentInfo).then((data) => {
            // setCount(count + 1)
        })
    }
    return (
        <Modal show={commentShow} centered className='userModal'>
            <button className='closeModal btn' onClick={handleCommentClose}><FontAwesomeIcon icon={faXmark} /></button>
            <h1>Comment</h1>
            <textarea className='form-control' onChange={(e) => setComment(e.target.value)} />
            <button className='btn btn-info mt-1' onClick={commentPost}>Add Comment</button>
        </Modal>
    )
}

export default CommentModal