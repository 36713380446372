import { API } from "../config";
import axios from 'axios';
import { put } from "./base-api";
// sub-category add
export const addArea = async (body = {}) => {
    try {
        const response = await axios.post(`${API}/addArea`, body);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


export const getAreaList = async () => {
    try {
        const response = await axios.get(`${API}/getAreaList`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


export const areaUpdate = async (id, body = {}) => {
    return put(`/areaUpdate/${id}`, body);
};
