import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { API } from "../../config";
import { viewProfile } from '../../api/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faComment, faCubes, faDotCircle, faEllipsis, faLink, faLocationArrow, faMobile, faPaperPlane, faPhone, faRightFromBracket, faShare, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { createdTime } from '../../Function/commonFunction';
import { Dropdown, DropdownButton, Collapse } from 'react-bootstrap';


const CommentBox = ({ userCommentShow ,deleteCommentReply, commentData, commentPost, card, userComment, setUserComment, deleteComment, replyShowBox, commentReply, setCommentReply, commentReplyPost, activeReplyId, setActiveReplyId }) => {
    const savedUserProfile = localStorage.getItem("niomit");
    const userProfile = JSON.parse(savedUserProfile);
    const [profileData, setProfileData] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        viewProfile(userProfile?.mobile).then((data) => {
            setProfileData(data.data);
        })
    }, [])
    return (
        <div>
            {userProfile?.mobile ? <>
                {commentData.length === 0 || userCommentShow ? <div className='postCommentBox'>
                   <figure>
                        <LazyLoadImage src={`${API}/${profileData?.image}`} alt="" className="img-fluid cardImg" />
                    </figure>
                    <textarea className='form-control' placeholder='Write your comment' value={userComment} onChange={(e) => setUserComment(e.target.value)}></textarea>
                    <button onClick={() => commentPost(card.id)}><FontAwesomeIcon icon={faPaperPlane} /></button>
                </div> : ""}
            </> : ""}

            {commentData ? <>
                {commentData.map((comment) => <div>
                    <div class="row align-items-center mt-3">
                        <div class="col-auto postImage">
                            <figure>
                                <img src={`${API}/${comment.comment_user_image}`} alt="" class="img-fluid" />
                            </figure>
                        </div>
                        <div class="col cardHeading"><a href={`/${comment.comment_user_id}`}>{comment.comment_user_name}</a>
                            <div class="d-flex card-link"><p>{createdTime(comment.comment_created_at)}</p></div></div>
                        <div className="col-auto">
                            <div className="vertical-center mobileIcon">
                                <DropdownButton id="dropdown-basic-button" title={<FontAwesomeIcon icon={faEllipsis} />}>
                                    <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                                    <Dropdown.Item onClick={() => deleteComment(comment.comment_id)}>Delete</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Report</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center card-link commentText">
                        <p>{comment.comment_text}</p>
                        {userProfile?.mobile ? <button className='replyBtn' onClick={() => replyShowBox(comment.comment_id)}>Reply</button> : ""}

                        {activeReplyId === comment.comment_id && (
                            <div className='postCommentBox mt-3 mb-2'>
                                <figure>
                                    <LazyLoadImage src={`${API}/${profileData?.image}`} width="40px" alt="" className="img-fluid" />
                                </figure>
                                <textarea placeholder='Reply comment' className='form-control' value={commentReply} onChange={(e) => setCommentReply(e.target.value)}></textarea>
                                <button onClick={() => commentReplyPost(comment.comment_id)}><FontAwesomeIcon icon={faPaperPlane} /></button>
                            </div>
                        )}
                        <Collapse in={open}>
                            <div id="example-collapse-text">
                                {comment.replies.map((reply) => <div className='commentReply'>
                                    <div class="row align-items-center">
                                        <div class="col-auto postImage">
                                            <figure>
                                                <img src={`${API}/${reply.reply_user_image}`} width="30px" alt="" class="img-fluid" />
                                            </figure>
                                        </div>
                                        <div class="col cardHeading"><a href={`/${reply.reply_user_id}`}>{reply.reply_user_name}</a>
                                            <div class="d-flex card-link"><p>{createdTime(reply.reply_created_at)}</p></div>
                                        </div>

                                        <div className="col-auto">
                                            <div className="vertical-center mobileIcon">
                                                <DropdownButton id="dropdown-basic-button" title={<FontAwesomeIcon icon={faEllipsis} />}>
                                                    <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => deleteCommentReply(reply.reply_id)}>Delete</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3">Report</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="align-items-center commentText">
                                        {reply.reply_text}
                                    </div>
                                </div>)}
                            </div>
                        </Collapse>

                        {comment.replies.length ? <div className='mt-1 mb-2'>
                            <button className='showReply' onClick={() => setOpen(!open)}>{open ? "Less replies" : "Show more replies"}</button>
                        </div> : ""}

                    </div>
                </div>)}
            </> : ""}
        </div>
    )
}

export default CommentBox