import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Pages/Home/Home';
import AdminGuard from './Component/AdminGuard/AdminGuard';
import Login from './Pages/Admin/Login/Login';
import Dashboard from './Pages/Admin/Dashboard/Dashboard';
import AddOption from './Pages/Admin/AddOption/AddOption';
import ViewPost from './Pages/ViewPost/ViewPost';
import ViewProfile from './Pages/ViewProfile/ViewProfile';
import Account from './Pages/Account/Account';

const Routing = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                     <Route element={<AdminGuard />}>
                       <Route path="/add-option" element={<AddOption />}></Route> 
                        <Route path="/my-dashboard" element={<Dashboard />}></Route>
                    </Route> 
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/account" element={<Account />}></Route>
                    <Route path="/post/:id" element={<ViewPost />}></Route>
                    <Route path="/:id" element={<ViewProfile />}></Route>
                    <Route path="/" element={<Home />}></Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default Routing