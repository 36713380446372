import React, { useEffect, useState } from 'react'
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import { postUpdate, singlePost } from '../../api/post';
import { useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { API } from "../../config";

const EditPost = (props) => {
    const { id } = useParams();
    const [image, setImage] = useState(null);
    const [description, setDescription] = useState('');
    const [file, setFile] = useState("");
    const [imageKey, setImageKey] = useState("");
    const savedUserProfile = localStorage.getItem("niomit");
    const userProfile = JSON.parse(savedUserProfile);


    function handleChange(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
        setImageKey(e.target.files[0])
    }
    useEffect(() => {
        singlePost(id).then((data) => {
            setDescription(data.data.description);
            setImage(data.data.image);
        })
    }, []);
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };
    const updatePost = () => {
        let postInfo ={
            "id": id,
            "created_by": userProfile?.mobile,
            "image": imageKey ? imageKey : "",
            "description": description
        }
        postUpdate(postInfo).then((data) => {
            props.setViewShow(false);
            props.setCount(props.count+1);
        })
     }
    return (
        <Modal show={props.viewShow} centered className='userModal'>
            <button className='closeModal btn' onClick={props.handleViewClose}><FontAwesomeIcon icon={faXmark} /></button>
            <div className='addPost'>
                <h1>Post</h1>

                <div className='inputField mt-3'>
                    <div>
                        <p>Post image</p>
                        {file.length ? <div className="text-center imgFile">{file && file.length > 0 ? <img src={file} alt="shop" /> : ""}</div> 
                        :<div className="text-center imgFile"><img src={`${API}/${image}`} alt="shop" /></div>}

                        <div className='inputImgField'>
                            <input type="file" name="file" id="file" onChange={handleChange} className="inputFile" />
                            <label for="file"><FontAwesomeIcon icon={faPlus} /> Choose a file</label>
                        </div>
                    </div>
                </div>
                <div className='inputField mt-3'>
                    <label>Details</label>
                    <div>
                        <textarea value={description} onChange={handleDescriptionChange} className='form-control' />
                    </div>
                </div>

                <div className='d-flex justify-content-center'>
                <button className='btn btn-info mt-3' onClick={updatePost}>Update</button>
                </div>
            </div>
        </Modal>
    )
}

export default EditPost