import React, { useEffect, useState } from 'react'
import AdminNav from '../../../Component/AdminNav/AdminNav'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { profileList, userActive } from '../../../api/user';

const Dashboard = () => {
  const [profileData, setProfileData] = useState([]);
  const [count, setCount] = useState(0);
  const savedUserProfile = localStorage.getItem("niomit");
  const userProfile = JSON.parse(savedUserProfile);
  useEffect(() => {
    profileList().then((data) => {
      setProfileData(data);
    })
  }, [count]);

  const userUpdate = (userMobile) => {
    userActive(userMobile).then((data) => {
      setCount(count + 1)
    })
  }
  return (
    <div>
      <AdminNav />
      <Container>
        <Row>
          <Col md={12} className='optionList'>
            <h1>User list</h1>
            <div className='categoriesList'>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Mobile</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Sub-category</th>
                    <th>City</th>
                    <th>Area</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {profileData.map((profile, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{profile.mobile}</td>
                      <td>{profile.user_name}</td>
                      <td>{profile.category}</td>
                      <td>{profile.sub_category}</td>
                      <td>{profile.city}</td>
                      <td>{profile.area}</td>
                      <td>
                        {profile.active ? <button className='btn btn-danger' onClick={() => userUpdate(profile.mobile)}>Disable</button>
                          : <button className='btn btn-info' onClick={() => userUpdate(profile.mobile)}>Active</button>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Dashboard
