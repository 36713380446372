import { API } from "../config";
import axios from 'axios';
import { put } from "./base-api";
// category add
export const addCategory = async (body = {}) => {
    try {
        const response = await axios.post(`${API}/addCategory`, body);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// category list
export const getCategoryList = async () => {
    try {
        const response = await axios.get(`${API}/getCategoryList`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const categoryUpdate = async (id, body = {}) => {
    return put(`/categoryUpdate/${id}`, body);
};
