import { API } from "../config";
import axios from 'axios';
import { get, put } from "./base-api";

// home data
export const homeData = async (type,category,subCategory,city,area) => {
    return get(`/homeData?type=${type}&category=${category}&subCategory=${subCategory}&city=${city}&area=${area}`);
};


// get Home Category
export const getHomeCategory = async () => {
    return get(`/getHomeCategory`);
};

// get Home Sub-Category
export const getHomeSubCategory = async (category) => {
    if (category) {
         return get(`/getHomeSubCategory/${category}`);   
    }
};

// get Home Sub-Category
export const getHomeCity = async () => {
    return get(`/getHomeCity`);
};
// get home area
export const getHomeArea = async (city) => {
    if (city) {
        return get(`/getHomeArea/${city}`);   
   }
};


