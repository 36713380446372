import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import { postCommentReply } from '../../api/post';

const CommentReply = ({ commentShow, postId, commentId, handleCommentClose }) => {


    const [comment, setComment] = useState();
    const savedUserProfile = localStorage.getItem("niomit");
    const userProfile = JSON.parse(savedUserProfile);
    const commentPost = () => {
        let commentInfo = {
            "id": uuidv4(),
            "comment": comment,
            "comment_id": commentId,
            "post_id": postId,
            "created_by": userProfile?.mobile,
        }
        postCommentReply(commentInfo).then((data) => {
            console.log('post', data);
        })
    }

    return (
        <Modal show={commentShow} centered className='userModal'>
            <button className='closeModal btn' onClick={handleCommentClose}><FontAwesomeIcon icon={faXmark} /></button>
            <h1>Comment</h1>
            <textarea className='form-control' onChange={(e) => setComment(e.target.value)} />
            <button className='btn btn-info mt-1' onClick={commentPost}>Add Comment</button>
        </Modal>
    )
}

export default CommentReply