import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
import { loginUser, numberCheck, passwordUpdate, registerUser } from '../../../api/user';
import "./Registration.css";
import { v4 as uuidv4 } from 'uuid';
import { addCity, getCity } from '../../../api/city';
import { addArea, getAreaList } from '../../../api/area';
import { getCategoryList } from '../../../api/category';
import { addSubCategory, getSubCategoryList } from '../../../api/sub-category';

const Registration = (props) => {
    const navigate = useNavigate();

    const loginShowClose = () => {
        props.setLoginShow(false);
    }

    const [userMobile, setUserMobile] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [otpValue, setOtpValue] = useState("");
    const [otpWrong, setOtpWrong] = useState(false);
    const [validLogin, setValidLogin] = useState(false);
    const [otpShow, setOtpShow] = useState("");
    const [numberValue, setNumberValue] = useState(false);
    // new method
    const [numberValidCheck, setNumberValidCheck] = useState(true);
    const [passwordCheck, setPasswordCheck] = useState(false);
    const [profileInfo, setProfileInfo] = useState(false);
    const [otpCheck, setOtpCheck] = useState(false);
    const [forgetOtp, setForgetOtp] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [imageKey, setImageKey] = useState("");
    const [name, setName] = useState("");
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [subCategoryId, setSubCategoryId] = useState("");
    const [cityId, setCityId] = useState("");
    const [areaId, setAreaId] = useState("");
    const [city, setCity] = useState("");
    const [area, setArea] = useState("");
    const [address, setAddress] = useState("");
    const [newPass, setNewPass] = useState("");
    const [fillUp, setFillUp] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryLists, setSubCategoryLists] = useState([]);
    const [cityLists, setCityLists] = useState([]);
    const [areaLists, setAreaLists] = useState([]);
    const [file, setFile] = useState();

    useEffect(() => {
        getCategoryList().then((data) => {
            setCategoryList(data);
        })
        getSubCategoryList().then((data) => {
            setSubCategoryLists(data);
        })
        getCity().then((data) => {
            setCityLists(data);
        })
        getAreaList().then((data) => {
            setAreaLists(data);
        })
    }, []);



    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };

    function generateRandomNumber() {
        const min = 10000;
        const max = 99999;
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }



    function handleChange(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
        setImageKey(e.target.files[0])
    }

    const handleShow = () => {
        if (userMobile.length === 0) {
            setNumberValue(true);
        } else {
            numberCheck(userMobile).then((data) => {
                if (data.exists === true) {
                    setNumberValidCheck(false);
                    setPasswordCheck(true);
                } else {
                    setNumberValidCheck(false);
                    setProfileInfo(true);
                }
            });
        }
    };

    const otpSave = () => {
        if (String(otpCode) === otpValue) {
            setOtpWrong(false);
            loginSave();
        } else {
            setOtpWrong(true);
        }
    };

    const loginSave = () => {

        const formData = new FormData();
        formData.append('image', imageKey);
        formData.append('mobile', userMobile);
        formData.append('password', userPassword);
        formData.append('user_name', name);
        formData.append('category', category);

        if (subCategoryId) {
            formData.append('subCategory', subCategoryId);
        }
        else {
            let subCat = uuidv4()
            let categoryInfo = {
                "id": subCat,
                "category": category,
                "sub_category": subCategory,
                "created_at": new Date().toISOString()
            }
            formData.append('subCategory', subCat);
            addSubCategory(categoryInfo).then((data) => {
                setSubCategory("");
            })
        }

        if (cityId) {
            formData.append('city', cityId);
        }
        else {
            let cityCode = uuidv4()
            let categoryInfo = {
                "id": cityCode,
                "city": city,
                "created_at": new Date().toISOString()
            }
            formData.append('city', cityCode);
            addCity(categoryInfo).then((data) => {
                setCity("");
            })
        }

        if (areaId) {
            formData.append('area', areaId);
        }
        else {
            let areaCode = uuidv4()
            let categoryInfo = {
                "id": areaCode,
                "city": cityId.length ? cityId :  uuidv4(),
                "area": area,
                "created_at": new Date().toISOString()
            }
            formData.append('area', areaCode);
            addArea(categoryInfo).then((data) => {
                setArea("");
            })
        }
        formData.append('address', address);
        registerUser(formData).then((data) => {
            if (!data.error) {
                localStorage.setItem("niomit", JSON.stringify(data));
                props.setLoginShow(false);
                // return navigate("/account");
            }
        });
    };

    const handleMobile = (event) => {
        const value = event.target.value;
        setUserMobile(value);
    };
    const handlePass = (event) => {
        const value = event.target.value;
        setUserPassword(value);
    };
    const handleOtp = (event) => {
        const value = event.target.value;
        setOtpValue(value);
    };

    const loginApi = () => {
        loginUser(userMobile, userPassword).then((data) => {
            if (!data.error) {
                localStorage.setItem("niomit", JSON.stringify(data));
                props.setLoginShow(false);
                return navigate(`/${data.mobile}`);
            } else {
                setValidLogin(true);
            }
        });
    };

    const profileApi = () => {
        if (name.length > 0 && userPassword.length > 0 && imageKey) {
            const randomNumber = generateRandomNumber();
            setOtpShow(randomNumber);
            setOtpCode(randomNumber);
            setProfileInfo(false);
            setOtpCheck(true);
            setFillUp(false);
        }
        else {
            setFillUp(true);
        }

    }
    const profileBackApi = () => {
        setProfileInfo(false);
        setNumberValidCheck(true);
    }

    const forgetCheck = () => {
        const randomNumber = generateRandomNumber();
        setOtpShow(randomNumber);
        setOtpCode(randomNumber);
        setForgetOtp(true);
        setPasswordCheck(false);
    }

    const forgetOtpCheck = () => {
        if (String(otpCode) === otpValue) {
            setForgetOtp(false);
            setNewPassword(true);
            setOtpWrong(false);
        } else {
            setOtpWrong(true);
        }
    }

    const newPasswordApi = () => {
        passwordUpdate(userMobile, newPass).then((data) => {
            if (!data.error) {
                localStorage.setItem("niomit", JSON.stringify(data));
                props.setLoginShow(false);
                return navigate("/my-course");
            }
        });
    }

    const [filteredResults, setFilteredResults] = useState(subCategoryLists);
    const handleSubCategory = (e) => {
        const value = e.target.value;
        setSubCategory(value);
        if (value.trim() === '') {
            setFilteredResults([]);
        } else {
            const results = subCategoryLists.filter(subCat =>
                subCat.sub_category.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredResults(results);
        }
    };


    const [filteredCity, setFilteredCity] = useState(cityLists);
    const handleCity = (e) => {
        const value = e.target.value;
        setCity(value);
        if (value.trim() === '') {
            setFilteredCity([]);
        } else {
            const results = cityLists.filter(subCat =>
                subCat.city.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredCity(results);
        }
    };

    const [filteredArea, setFilteredArea] = useState(areaLists);
    const handleArea = (e) => {
        const value = e.target.value;
        setArea(value);
        if (value.trim() === '') {
            setFilteredArea([]);
        } else {
            const results = areaLists.filter(subCat =>
                subCat.area.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredArea(results);
        }
    };

    return (
        <>
            <Modal show={props.loginShow} onHide={loginShowClose} centered className="loginModal">
                <Modal.Body>
                    <section className="loginBox">
                        <button className="loginClose" onClick={loginShowClose}><FontAwesomeIcon icon={faClose} /></button>
                        {/* number mobile password */}
                        {numberValidCheck ? <>
                            <div className="loginApp">
                                <>
                                    <div className="inputs">
                                        <input
                                            type="text"
                                            onChange={handleMobile}
                                            value={userMobile}
                                            placeholder="Mobile number"
                                        />
                                        {numberValue ? <span>Enter valid number</span> : <></>}
                                    </div>
                                </>
                                <div className="loginFooter">
                                    <button onClick={handleShow}>Next</button>
                                </div>
                            </div>
                        </> : <></>}
                        {/* Login password */}
                        {passwordCheck ? <>
                            <div className="loginApp flex-column">

                                <input type="password" onChange={handlePass} name="" placeholder="password" />

                                {validLogin ? <p className="text-danger">Invalid information</p> : ""}

                                <div className="loginFooter">
                                    <button onClick={loginApi}>Login</button>
                                </div>

                                <button className="btn" onClick={forgetCheck}>Forget password</button>
                            </div>
                        </> : <></>}
                        {/* profile add */}
                        {profileInfo ? <>
                            <div className="loginApp">
                                <h1 style={{ fontSize: "32px" }}>Profile</h1>
                                <div className="inputs">

                                    <div className="text-center imgFile">{file && file.length > 0 ? <img src={file} alt="shop" /> : ""}</div>

                                    <div className='inputImgField'>
                                        <input type="file" name="file" id="file" onChange={handleChange} className="inputFile" />
                                        <label for="file"><FontAwesomeIcon icon={faPlus} /> Choose a file</label>
                                    </div>
                                    <input type="text" name="userName" onChange={(e) => setName(e.target.value)} placeholder="Enter your name" />
                                    <input type="password" name="password" onChange={handlePass} placeholder="Enter your password" />
                                    <div className='inputField mt-3'>
                                        <label>Select Profile</label>
                                        <Form.Select aria-label="Default select example" onChange={handleCategoryChange}>
                                            <option value="">Select Profile Option</option>
                                            {categoryList.map((category) =>
                                                <option value={`${category.id}`}>{category.category}</option>
                                            )}
                                        </Form.Select>
                                    </div>
                                    <input type="text" name="userName" value={subCategory} onChange={handleSubCategory} placeholder="Enter your sub-category" />
                                    <ul className='filterList'>
                                        {filteredResults.map((subCat, index) => (
                                            <li key={index} onClick={() => { setSubCategoryId(subCat.id); setSubCategory(subCat.sub_category); }}>{subCat.sub_category}</li>
                                        ))}
                                    </ul>
                                    <input type="text" name="userName" value={city} onChange={handleCity} placeholder="Enter your city" />
                                    <ul className='filterList'>
                                        {filteredCity.map((subCat, index) => (
                                            <li key={index} onClick={() => { setCityId(subCat.id); setCity(subCat.city); }}>{subCat.city}</li>
                                        ))}
                                    </ul>
                                    <input type="text" name="userName" value={area} onChange={handleArea} placeholder="Enter your area" />
                                    <ul className='filterList'>
                                        {filteredArea.map((subCat, index) => (
                                            <li key={index} onClick={() => { setAreaId(subCat.id); setArea(subCat.area); }}>{subCat.area}</li>
                                        ))}
                                    </ul>
                                    <input type="text" name="userName" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Enter your address" />

                                    {fillUp && fillUp ? <p className="text-center text-danger text-bold">Please fillup all the information</p> : ""}

                                    <div className="profileFooter">
                                        <button onClick={profileBackApi}>Back</button>
                                        <button onClick={profileApi}>Continue</button>
                                    </div>

                                </div>
                            </div>
                        </> : <></>}
                        {/* OTP code */}
                        {otpCheck ? <>
                            <div className="loginApp">

                                <input
                                    type="text"
                                    onChange={handleOtp}
                                    placeholder="OTP number"
                                />
                                <br />
                                <span>{otpShow}</span> <br />
                                {otpWrong ? (
                                    <>
                                        <span className="text-danger">This code is not correct</span>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <br />
                                <div className="loginFooter">
                                    <button onClick={otpSave}>Login</button>
                                </div>
                            </div>
                        </> : <></>}
                        {/* forget OTP code */}
                        {forgetOtp ? <>
                            <div className="loginApp">
                                <input
                                    type="text"
                                    onChange={handleOtp}
                                    placeholder="OTP number"
                                />
                                <br />
                                <span>{otpShow}</span> <br />
                                {otpWrong ? (
                                    <>
                                        <span>This code is not correct</span>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <br />
                                <div className="loginFooter">
                                    <button onClick={forgetOtpCheck}>Login</button>
                                </div>
                            </div>
                        </> : <></>}
                        {/* new password save */}
                        {newPassword ? <>
                            <div className="loginApp">
                                <input
                                    type="password"
                                    onChange={(e) => setNewPass(e.target.value)}
                                    placeholder="Enter your password"
                                />
                                <br />
                                <div className="loginFooter">
                                    <button onClick={newPasswordApi}>Login</button>
                                </div>
                            </div>
                        </> : <></>}
                    </section>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Registration