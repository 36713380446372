import React, { useEffect, useState } from 'react'
import { commentDelete, commentReplyDelete, postComment, postCommentList, postCommentReply, postDisLike, postLike, viewPost } from '../../api/post';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { API } from "../../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faCubes, faEllipsis, faLink, faLocationArrow, faShare, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import EditPost from '../../Component/EditPost/EditPost';
import CommentReply from '../../Component/CommentReply/CommentReply';
import Header from '../../Component/Header/Header';
import { createdTime } from '../../Function/commonFunction';
import { v4 as uuidv4 } from 'uuid';
import CommentBox from '../../Component/CommentBox/CommentBox';

const ViewPost = () => {
    const { id } = useParams();
    const [postData, setPostData] = useState({});
    const [viewShow, setViewShow] = useState(false);
    const handleViewClose = () => setViewShow(false);
    const handleViewShow = () => setViewShow(true);
    const [commentData, setCommentData] = useState([]);
    const [userCommentShow, setUserCommentShow] = useState(false);
    const [commentShow, setCommentShow] = useState(false);
    const handleCommentClose = () => setCommentShow(false);
    const [commentId, setCommentId] = useState("");
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [activeCommentId, setActiveCommentId] = useState(null);
    const [isCopied, setIsCopied] = useState(false);
    const savedUserProfile = localStorage.getItem("niomit");
    const userProfile = JSON.parse(savedUserProfile);
    const [activeReplyId, setActiveReplyId] = useState(null);
    const [commentReply, setCommentReply] = useState('');
    const [postId, setPostId] = useState("");
    const [open, setOpen] = useState(false);
    const [userComment, setUserComment] = useState('');


    useEffect(() => {
        viewPost(id).then((data) => {
            console.log('post',data.data[0]);
            
            setPostData(data.data[0]);
        })
        postCommentList(id,page,limit).then((data) => {
            setCommentData(data.comments)
            setUserCommentShow(data.user_comment)
        })
    }, [count]);

    const replyComment = (commentId) => {
        setCommentId(commentId);
        setCommentShow(true);
    }

    const commentShowBox = (id) => {
        setActiveCommentId(activeCommentId === id ? null : id); // Toggle visibility
    };

    const copyUrlToClipboard = (postId) => {
        const url = window.location.href;

        navigator.clipboard.writeText(`${url}post/${postId}`).then(() => {
            setIsCopied(true);
        // Hide the animation after 2 seconds
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
        }).catch(err => {
            console.error('Failed to copy URL: ', err);
        });
    };
    const likePost = (postId, condition) => {
        let postInfo = {
            "id": uuidv4(),
            "post_id": postId,
            "created_by": userProfile?.mobile,
            "is_liked": condition
        }
        postLike(postInfo).then((data) => {
            setCount(count + 1)
        })
    }
    const DislikePost = (postId, condition) => {
        let postInfo = {
            "id": uuidv4(),
            "post_id": postId,
            "created_by": userProfile?.mobile,
            "is_liked": condition
        }
        postDisLike(postInfo).then((data) => {
            setCount(count + 1)
        })
    }
    const likeDislike = (postCheck, postId) => {
        setCount(count + 1)
        if (postCheck === "like") {
            if (postData.is_liked === true) {
                likePost(postId, false)
                DislikePost(postId, true)
            } else {
                likePost(postId, true)
                DislikePost(postId, false)
            }

        } else {
            if (postData.is_disliked === true) {
                likePost(postId, true)
                DislikePost(postId, false)
            } else {
                likePost(postId, false)
                DislikePost(postId, true)
            }
        }
    }

    const deleteComment = (commentId) => {
        commentDelete(commentId).then((data) => {
            setCount(count + 1);
        })
    }
    const deleteCommentReply = (commentId) => {
        commentReplyDelete(commentId).then((data) => {
            setCount(count + 1);
        })
    }


    const replyShowBox = (id) => {
        setActiveReplyId(activeReplyId === id ? null : id);
    };

    const commentReplyPost = (commentId) => {
        let commentInfo = {
            "id": uuidv4(),
            "comment": commentReply,
            "comment_id": commentId,
            "post_id": postId,
            "created_by": userProfile?.mobile,
        }
        postCommentReply(commentInfo).then((data) => {
            setCount(count + 1);
            setCommentReply("");
            setOpen(true);
        })
    }

    const commentPost = (postId) => {
        let commentInfo = {
            "id": uuidv4(),
            "comment": userComment,
            "post_id": postId,
            "created_by": userProfile?.mobile,
        }
        if (userComment) {
            postComment(commentInfo).then((data) => {
                setCount(count + 1);
                setUserComment("")
            })
        }
    }


    return (
        <div>
        <Header />
        <Container>
            <Row className='d-flex justify-content-center'>
                <Col md={12}>
                    <div className="myCard">
                        <div className="row align-items-center">
                            <div className="col-auto postImage">
                                <figure>
                                    <LazyLoadImage src={`${API}/${postData.user_image}`} alt="" className="img-fluid cardImg" />
                                </figure>
                            </div>
                            <div className="col cardHeading">
                                <h3>
                                    <a href={`post/${postData.id}`}>{postData.user_name}</a>
                                </h3>
                                <div className="d-flex">
                                    <p className="card-link">
                                        <FontAwesomeIcon icon={faCubes} />
                                        {postData.user_category}
                                    </p>
                                    <p className="card-link">
                                        <FontAwesomeIcon icon={faLocationArrow} />
                                        {createdTime(postData.created_at)}
                                    </p>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="vertical-center">
                                    <div className="vertical-center mobileIcon">
                                        <DropdownButton id="dropdown-basic-button" title={<FontAwesomeIcon icon={faEllipsis} />}>
                                            <Dropdown.Item onClick={handleViewShow}>Edit</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Report</Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='postDescription'>
                                    <p>{postData.description}</p>
                                </div>
                                <div className=''>
                                    <figure>
                                        <LazyLoadImage src={`${API}/${postData.image}`} alt="" className="img-fluid postImg" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <div className="vertical-center">
                                <p className={postData.is_liked ? "postLike" : "postDisLike"} onClick={() => likeDislike("like", postData.id)}>
                                    <FontAwesomeIcon icon={faThumbsUp} /> <span>{postData.like_count}</span>
                                </p>
                            </div>
                            <div className="vertical-center">
                                <p className={postData.is_disliked ? "postLike" : "postDisLike"} onClick={() => likeDislike("dislike", postData.id)}>
                                    <FontAwesomeIcon icon={faThumbsDown} /> <span>{postData.dislike_count}</span>
                                </p>
                            </div>
                            <div className="vertical-center postDisLike">
                                <FontAwesomeIcon icon={faComment} onClick={() => commentShowBox(postData.id)} /> {postData.comment_count ? postData.comment_count : ""}
                            </div>
                            <div className="vertical-center">
                                <span className="postDisLike" onClick={() => copyUrlToClipboard(postData.id)}>
                                    <FontAwesomeIcon icon={faShare} />
                                    {isCopied && (
                                        <div className="copy-tooltip">
                                          Copied!
                                        </div>
                                      )}
                                </span>
                                
                            </div>
                        </div>
                    </div>
                    <div className='commentList'>
                    <CommentBox userCommentShow={userCommentShow} activeReplyId={activeReplyId} deleteCommentReply={deleteCommentReply} commentReply={commentReply} setCommentReply={setCommentReply} commentReplyPost={commentReplyPost} commentData={commentData} commentPost={commentPost} replyShowBox={replyShowBox} deleteComment={deleteComment} card={postData} userComment={userComment} setUserComment={setUserComment} />
                    </div>
                </Col>
            </Row>
            <EditPost count={count} setCount={setCount} viewShow={viewShow} setViewShow={setViewShow} handleViewClose={handleViewClose} />
            <CommentReply commentId={commentId} postId={id} commentShow={commentShow} handleCommentClose={handleCommentClose} />
        </Container>
        </div>
    )
}

export default ViewPost