import React, { useState, useEffect } from 'react'
import { viewProfile, viewUserPost } from '../../api/user';
import { useParams } from 'react-router-dom';
import { API } from "../../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes, faLink, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { Col, Container, Row } from 'react-bootstrap';
import "./ViewProfile.css";
import PostCard from '../../Component/PostCard/PostCard';
import Header from '../../Component/Header/Header';

const ViewProfile = () => {
    const { id } = useParams();
    const [profileData, setProfileData] = useState({});
    const [searchInput, setSearchInput] = useState('');
    const [type, setType] = useState('post');
    const [postData, setPostData] = useState([]);
    const [count, setCount] = useState(0);
    const [postShow, setPostShow] = useState(false);
    const handlePostClose = () => setPostShow(false);
    const handlePostShow = () => setPostShow(true);
    useEffect(() => {
        viewProfile(id).then((data) => {
            setProfileData(data.data);
        })
        viewUserPost(id).then((data) => {
           if (data) {
            setPostData(data);
           }
        })
    }, [count]);
    return (
       <div>
       <Header searchInput={searchInput} setSearchInput={setSearchInput} type={type} setType={setType} />
       <Container>
       <Row className='d-flex justify-content-center mt-5'>
           {/*<Col md={10}>
               <div className="myCard">
                   <div className="row align-items-center mb-5">
                       <div className="col-auto profileImage">
                           <figure>
                               <LazyLoadImage src={`${API}/${profileData.image}`} alt="" className="img-fluid cardImg" />
                           </figure>
                       </div>
                       <div className="col cardHeading">
                           <h3>
                               <a href={`post/${profileData.id}`}>{profileData.user_name}</a>
                           </h3>
                           <div className="d-flex">
                               <p className="card-link">
                                   <FontAwesomeIcon icon={faCubes} />
                                   Address: {profileData.address}
                               </p>
                               <p className="card-link">
                                   <FontAwesomeIcon icon={faLocationArrow} />
                                   Create: {profileData.created_at}
                               </p>
                           </div>
                           <div className="d-flex">
                               <p className="card-link">
                                   <FontAwesomeIcon icon={faCubes} />
                                   Category: {profileData.category}
                               </p>
                               <p className="card-link">
                                   <FontAwesomeIcon icon={faLocationArrow} />
                                   Sub-Category: {profileData.sub_category}
                               </p>
                           </div>
                           <div className="d-flex">
                               <p className="card-link">
                                   <FontAwesomeIcon icon={faCubes} />
                                   City: {profileData.city}
                               </p>
                               <p className="card-link">
                                   <FontAwesomeIcon icon={faLocationArrow} />
                                   Area: {profileData.area}
                               </p>
                           </div>
                       </div>
                       <div className="col-auto">
                           <div className="vertical-center">
                               <a href={`tel:+88${profileData.mobile}`}>
                                   <FontAwesomeIcon icon={faLink} />
                               </a>
                           </div>
                       </div>
                   </div>
               </div>
           </Col> 
           
           */}
           <Col md={12}>
               <Col md={12}>
                   <div className='d-flex justify-content-center homeFilter mb-4 addPostBtn'>
                       <button onClick={handlePostShow}>Add Post</button>
                   </div>
               </Col>
               <Row>
                   {postData && postData?.map((data) => <Col md={12}><PostCard card={data} count={count} setCount={setCount} /> </Col>)}
               </Row>
           </Col>
       </Row>
   </Container>
       </div>
    )
}

export default ViewProfile