export function createdTime(timestamp) {
    const date = new Date(timestamp);

    // Format the date (e.g., "25 Aug 2024")
    const dateOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
  
    // Format the time (e.g., "07:15")
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedTime = date.toLocaleTimeString('en-GB', timeOptions).replace('AM', '').replace('PM', '').trim();
  
    return `${formattedDate} at ${formattedTime}`;
}