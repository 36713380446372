import { API } from "../config";
import axios from 'axios';
import { put } from "./base-api";
// admin login
export const loginAdmin = async (body = {}) => {
    try {
        const response = await axios.post(`${API}/adminLogin`, body);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const isAuthenticate = () => {
    if (typeof window == undefined) {
        return false;
    }
    if (localStorage.getItem("niomit")) {
        return JSON.parse(localStorage.getItem("niomit") || '{}');
    } else {
        return false;
    }
};
export const isAdminAuthenticate = () => {
    if (typeof window == undefined) {
        return false;
    }
    if (localStorage.getItem("niomadmin")) {
        return JSON.parse(localStorage.getItem("niomadmin") || '{}');
    } else {
        return false;
    }
};
// logout
export const logout = async () => {
    localStorage.removeItem("niomit");
};

