import { API } from "../config";
import axios from 'axios';
import { put } from "./base-api";
// sub-category add
export const addCity = async (body = {}) => {
    try {
        const response = await axios.post(`${API}/addCity`, body);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


export const getCity = async () => {
    try {
        const response = await axios.get(`${API}/getCity`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


export const cityUpdate = async (id, body = {}) => {
    return put(`/cityUpdate/${id}`, body);
};


// sub-category list
export const getCityList = async () => {
    try {
        const response = await axios.get(`${API}/getCityList`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
