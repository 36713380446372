import { API } from "../config";
import axios from 'axios';
import { put } from "./base-api";
// sub-category list
export const getSubCategory = async () => {
    try {
        const response = await axios.get(`${API}/getSubCategory`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// sub-category list
export const getCategoryLists = async () => {
    try {
        const response = await axios.get(`${API}/getCategoryList`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


// sub-category add
export const addSubCategory = async (body = {}) => {
    try {
        const response = await axios.post(`${API}/addSubCategory`, body);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


export const getSubCategoryList = async () => {
    try {
        const response = await axios.get(`${API}/getSubCategoryList`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const subCategoryUpdate = async (id, body = {}) => {
    return put(`/subCategoryUpdate/${id}`, body);
};
