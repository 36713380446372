import React from 'react';
import { API } from "../../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes, faLocationArrow, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import "./Profile.css";

const Profile = (props) => {
    return (
        <div>
            <div className="myCard">
                <div className="row">
                    <div className="col-auto cardImage">
                        <figure>
                            <LazyLoadImage src={`${API}/${props.data.image}`} alt="" className="img-fluid cardImg" />
                        </figure>
                    </div>
                    <div className="col cardHeading">
                            <a href={`${props.data.mobile}`}>{props.data.user_name}</a>
                        <div className="d-flex">
                            <p className="card-link">
                                <FontAwesomeIcon icon={faCubes} />
                                {props.data.category}
                            </p>
                            <p className="card-link">
                                <FontAwesomeIcon icon={faLocationDot} />
                                {props.data.address}
                            </p>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="vertical-center mobileIcon">
                            <a href={`tel:+88${props.data.mobile}`}>
                                <FontAwesomeIcon icon={faPhone} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile
