import React, { useEffect, useState } from 'react';
import { API } from "../../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faComment, faCubes, faDotCircle, faEllipsis, faLink, faLocationArrow, faMobile, faPaperPlane, faPhone, faRightFromBracket, faShare, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { commentDelete, commentReplyDelete, postComment, postCommentList, postCommentReply, postDisLike, postLike } from '../../api/post';
import { v4 as uuidv4 } from 'uuid';
import "./PostCard.css";
import { Col, Collapse, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import CommentModal from '../CommentModal/CommentModal';
import { viewProfile } from '../../api/user';
import { createdTime } from '../../Function/commonFunction';
import CommentBox from '../CommentBox/CommentBox';

const PostCard = ({ card, count, setCount }) => {

    const [postId, setPostId] = useState("");
    const [commentShow, setCommentShow] = useState(false);
    const [userCommentShow, setUserCommentShow] = useState(false);
    const [activeCommentId, setActiveCommentId] = useState(null);
    const [activeReplyId, setActiveReplyId] = useState(null);
    const [userComment, setUserComment] = useState('');
    const [commentReply, setCommentReply] = useState('');
    const [commentData, setCommentData] = useState([]);
    const handleCommentClose = () => setCommentShow(false);
    const [profileData, setProfileData] = useState({});
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(1);
    const [isCopied, setIsCopied] = useState(false);
    const handleCommentShow = (postId) => {
        setCommentShow(true);
        setPostId(postId);
    }
    const savedUserProfile = localStorage.getItem("niomit");
    const userProfile = JSON.parse(savedUserProfile);

    useEffect(() => {
        viewProfile(userProfile?.mobile).then((data) => {
            setProfileData(data.data);
        })
    }, [])

    const likePost = (postId, condition) => {
        let postInfo = {
            "id": uuidv4(),
            "post_id": postId,
            "created_by": userProfile?.mobile,
            "is_liked": condition
        }
        postLike(postInfo).then((data) => {
            setCount(count + 1)
        })
    }
    const DislikePost = (postId, condition) => {
        let postInfo = {
            "id": uuidv4(),
            "post_id": postId,
            "created_by": userProfile?.mobile,
            "is_liked": condition
        }
        postDisLike(postInfo).then((data) => {
            setCount(count + 1)
        })
    }

    const likeDislike = (postCheck, postId) => {
        setCount(count + 1)
        if (postCheck === "like") {
            if (card.is_liked === true) {
                likePost(postId, false)
                DislikePost(postId, true)
            } else {
                likePost(postId, true)
                DislikePost(postId, false)
            }

        } else {
            if (card.is_disliked === true) {
                likePost(postId, true)
                DislikePost(postId, false)
            } else {
                likePost(postId, false)
                DislikePost(postId, true)
            }
        }
    }



    const copyUrlToClipboard = (postId) => {
        const url = window.location.href;

        navigator.clipboard.writeText(`${url}post/${postId}`).then(() => {
            setIsCopied(true);
            // Hide the animation after 2 seconds
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }).catch(err => {
            console.error('Failed to copy URL: ', err);
        });
    };

    const commentShowBox = (id) => {
        setActiveCommentId(activeCommentId === id ? null : id); // Toggle visibility
    };

    const replyShowBox = (id) => {
        setActiveReplyId(activeReplyId === id ? null : id);
    };



    const commentPost = (postId) => {
        let commentInfo = {
            "id": uuidv4(),
            "comment": userComment,
            "post_id": postId,
            "created_by": userProfile?.mobile,
        }
        if (userComment) {
            postComment(commentInfo).then((data) => {
                setCount(count + 1);
                setUserComment("")
            })
        }
    }

    useEffect(() => {
        postCommentList(card.id, page, limit).then((data) => {
            setCommentData(data.comments);
            setUserCommentShow(data.user_comment);
        })
    }, [count]);


    const commentReplyPost = (commentId) => {
        let commentInfo = {
            "id": uuidv4(),
            "comment": commentReply,
            "comment_id": commentId,
            "post_id": postId,
            "created_by": userProfile?.mobile,
        }
        postCommentReply(commentInfo).then((data) => {
            setCount(count + 1);
            setCommentReply("");
            setOpen(true);
        })
    }

    const deleteComment = (commentId) => {
        commentDelete(commentId).then((data) => {
            setCount(count + 1);
        })
    }
    const deleteCommentReply = (commentId) => {
        commentReplyDelete(commentId).then((data) => {
            setCount(count + 1);
        })
    }


    return (
        <div>
            <div className="myCard">
                <div className="row align-items-center">
                    <div className="col-auto postImage">
                        <figure>
                            <LazyLoadImage src={`${API}/${card.user_image}`} alt="" className="img-fluid cardImg" />
                        </figure>
                    </div>
                    <div className="col cardHeading">
                        <a href={`/${card.created_by}`}>{card.user_name}</a>
                        <div className="d-flex">
                            <p className="card-link">
                                <FontAwesomeIcon icon={faCubes} />
                                {card.category}
                            </p>
                            <p className="card-link">
                                <FontAwesomeIcon icon={faClock} />
                                {createdTime(card.created_at)}
                            </p>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="vertical-center mobileIcon">
                            <DropdownButton title={<FontAwesomeIcon icon={faEllipsis} />}>
                                <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Report</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <a className="col postCardBox" href={`post/${card.id}`}>
                        <div className='postDescription'>
                            <p>{card.description}</p>
                        </div>
                        {card.image.length ? <div className='postView'>
                            <figure>
                                <LazyLoadImage src={`${API}/${card.image}`} alt="" className="img-fluid postImg" />
                            </figure>
                        </div> : ""}
                    </a>
                </div>
                <Row>
                    <Col md={12}>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <div className="vertical-center">
                                <p className={card.is_liked ? "postLike" : "postDisLike"} onClick={() => likeDislike("like", card.id)}>
                                    <FontAwesomeIcon icon={faThumbsUp} /> <span>{card.like_count}</span>
                                </p>
                            </div>
                            <div className="vertical-center">
                                <p className={card.is_disliked ? "postLike" : "postDisLike"} onClick={() => likeDislike("dislike", card.id)}>
                                    <FontAwesomeIcon icon={faThumbsDown} /> <span>{card.dislike_count}</span>
                                </p>
                            </div>
                            <div className="vertical-center postDisLike">
                                <FontAwesomeIcon icon={faComment} onClick={() => commentShowBox(card.id)} /> {card.comment_count ? card.comment_count : ""}
                            </div>
                            <div className="vertical-center">
                                <span className="postDisLike" onClick={() => copyUrlToClipboard(card.id)}>
                                    <FontAwesomeIcon icon={faShare} />
                                    {isCopied && (
                                        <div className="copy-tooltip">
                                            Copied!
                                        </div>
                                    )}
                                </span>

                            </div>
                        </div>

                        <CommentBox userCommentShow={userCommentShow} activeReplyId={activeReplyId} deleteCommentReply={deleteCommentReply} commentReply={commentReply} setCommentReply={setCommentReply} commentReplyPost={commentReplyPost} commentData={commentData} commentPost={commentPost} replyShowBox={replyShowBox} deleteComment={deleteComment} card={card} userComment={userComment} setUserComment={setUserComment} />

                    </Col>
                </Row>
            </div>
            <CommentModal postId={postId} commentShow={commentShow} handleCommentClose={handleCommentClose} />

        </div>
    )
}

export default PostCard
